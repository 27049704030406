/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
// @ts-ignore
import ant from "../images/hero3b.jpg";
// @ts-ignore
import antSmall from "../images/hero4b.jpg";
// @ts-ignore
import brain from "../images/brain.png";
// @ts-ignore
import fracture from "../images/fracture.png";
// @ts-ignore
import removed from "../images/removed1.png";
// @ts-ignore
import removedSmall from "../images/removed2.png";
// @ts-ignore
import sleep from "../images/sleep.png";
import PrimaryButton from "../components/PrimaryButton";
import { DARK_MODE } from "../constants";

const USED_REMOVED = false
const buttonHighlight= DARK_MODE ? "border-white hover:border-grey-200 hover:bg-indigo-400" : "border-indigo-600 hover:border-indigo-800 hover:bg-indigo-100"
const buttonText= DARK_MODE ? "text-white hover:text-gray-200" : "text-gray-800 hover:text-gray-500"
const testimonialHeader = DARK_MODE ? "text-white" : "text-gray-800"

export default function HomePage(props: PageProps) {
  const mainBg = DARK_MODE ? "bg-indigo-600" : "bg-white"
  const homeText = DARK_MODE ? "text-white " : "text-indigo-600"

  return (
    <div className={mainBg}>
      <SEO title="Anthony DiFiglio" includeMainTitle={false} image={ant} />
      <NavBar {...props} />
      <main className={mainBg}>
        <div className="relative overflow-hidden" id="root">
          <div className="lg:h-screen lg:flex lg:flex-row lg:items-center lg:w-full">
            <div className="px-10 lg:pb-8 lg:w-full">
              <main className="max-w-7xl sm:px-6 mt-12 lg:mt-0 mb-10 lg:mb-0 h-full ">
                <div className="text-center lg:text-left">
                  {/* <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block text-indigo-600 xl:inline">
                      Anthony here!
                    </span>
                  </h1> */}
                  <p className={homeText + "text-base sm:max-w-xl sm:mx-auto lg:mx-0 font-bold text-2xl sm:text-3xl md:text-4xl"}>
                    I help people with all kinds of challenges related to the
                    mind and body. Before you count yourself out, let's chat.
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    {/* <CalendarButton /> */}
                    <PrimaryButton analyticsLink="MAIN_FREE_CHAT" text="Free 15 min Chat" href="/chat" />
                  </div>
                </div>
              </main>
            </div>
            <img
              className="hidden lg:block w-full object-cover object-top sm:h-96 md:h-3/6 lg:h-full lg:w-full mr-20 sm:rounded-lg mx-auto max-w-prose"
              src={USED_REMOVED ? removed : ant}
              alt="Picture of Anthony"
            />
            <img
              className="lg:hidden h-76 w-full object-cover object-top sm:h-full lg:w-full sm:rounded-lg mx-auto max-w-prose"
              src={USED_REMOVED ? removedSmall : antSmall}
              alt=""
            /> 
            {/* <img
              className="hidden lg:block h-76 w-full object-cover object-top sm:h-96 md:h-3/6 lg:h-full lg:w-full"
              src={USED_REMOVED ? removed : ant}
              alt=""
            />
            <img
              className="lg:hidden h-76 w-full object-cover object-top sm:h-full lg:w-full"
              src={USED_REMOVED ? removedSmall : antSmall}
              alt=""
            /> */}
          </div>
        </div>
        <div className="self-center mt-20 mx-10 sm:mx-0 md:mt-40 mb-20 lg:mb-32">
          <div className={testimonialHeader + " max-w-2xl mx-auto text-base text-center font-medium text-3xl"}>
            Select a circle below to hear about client experiences restoring these symptoms
          </div>
        </div>
        <div className={mainBg + " w-full pb-40"}>
          <div className="md:grid md:grid-cols-3 items-center">
            <CircleButton text="Stress, Anxiety, Fatigue, Mood" href="testimonials-1" src={brain} />
            <CircleButton text="Bone Loss, Strength, Pain, Gut" href="testimonials-2" src={fracture} />
            <CircleButton text="Breathing, Nutrition, Sleep" href="testimonials-3" src={sleep} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}


const CircleButton = ({text, src, href}: {text: string, src: string, href: string}) => (
  <a className="flex flex-col items-center mb-16 md:mb-0" href={href}>
    <img className={buttonHighlight + " h-52 aspect-square mb-6 p-8 rounded-full border-2 lg:border-4"} src={src} />
    <div className={buttonText + " text-center font-bold text-xl"}>
      {text}
    </div>
  </a>
)